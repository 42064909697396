import React from 'react';
import GoogleMapReact from 'google-map-react';

import icon from '../../images/msg.svg';
import marker from '../../images/marker.svg';

const Marker = () => <img className="marker" src={marker} alt=""/>;

const Contact = () => {
  return (
    <div className="contact-section ">
      <div className="wrapper">
        <div className="info">
          <h2>Instituto Hidalgo Bilingüe</h2>
          <p>
            Av. de la Iglesia No. 16, Col. Mayorazgos del Bosque <br/>
            Arboledas, Atizapán de Zaragoza, Estado de México C.P. 52957
          </p>
          <div className="to-left">
            <a className="tel header-secondary" href="tel:5553795977">
              <span>Tel: (55) 5379-5977.</span>
            </a>
          </div>
          <div className="to-left">
            <a className="primary-button" href="mailto:contacto@ihb.edu.mx">
              <span>Enviar Mensaje</span>
              <img src={icon} alt=""/>
            </a>
          </div>
        </div>
        <div id="map">
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyB2xcpAOp3eAhv-CfmyfIND8VO9b7b6CX8' }}
            defaultCenter={{ lat: 19.560973, lng: -99.226117}}
            defaultZoom={18}
          >
            <Marker
              lat={19.561020}
              lng={-99.226124}
            />
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
}

export default Contact;