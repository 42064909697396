import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "../components/slider"
import News from "../components/landing/News"
import About from "../components/landing/About"
import Model from "../components/landing/Model"
import PrincipalMsg from "../components/landing/PrincipalMsg"
import JoinUs from "../components/join"
import Contact from "../components/landing/Contact"

const IndexPage = () => (
  <Layout lightHeader>
    <SEO title="Inicio" />
    <Slider />
    <News />
    <About />
    <Model />
    <PrincipalMsg />
    <JoinUs />
    <Contact />
  </Layout>
)

export default IndexPage
