import React from 'react';

import image from '../../images/gallery/img29.jpg';
import blueCircle from '../../images/blue-circle.svg';

const PrincipalMsg = () => (
  <div className="principal-section wrapper">
    <img src={blueCircle} className="circle" alt=""/>
    <div className="message">
      <h1>
        Con un esfuerzo conjunto formaremos al hombre del mañana
      </h1>
      <p>
        Es un honor para el instituto contar con la confianza que ustedes padres de familia depositan en nosotros, al momento de brindarnos la oportunidad de educar al tesoro más preciado que tienen: "sus hijos", para continuar en el instituto con la labor que en común tenemos, la educación y formación de sus pequeños. Confianza que a su vez representa para nosotros una gran responsabilidad que asumimos con mucho agrado.
      </p>
      <p className="bold">
        Directora General del Plantel
      </p>
    </div>
    <div className="image">
      <img src={image} alt=""/>
    </div>
  </div>
);

export default PrincipalMsg;