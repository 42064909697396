import React from 'react';
import bulb from '../../images/bulb.svg';

const Skill = ({ label }) => (
  <div className="skill">
    <div className="icon">
      <img src={bulb} alt=""/>
    </div>
    <span>{label}</span>
  </div>
);

export default Skill