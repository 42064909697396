import React from 'react';
import Skill from '../atomic/Skill';
import PrimaryButton from '../atomic/PrimaryButton';

const Model = () => (
  <div className="model-section">
    <div className="wrapper">
      <h2>Nuestro Modelo Educativo</h2>
      <p>
        Centramos el objetivo primordial en permitir el desarrollo de las habilidades y competencias del alumno. La educación personalizada aprovecha las posibilidades que ofrece la parte individual y personal de los alumnos, orientándose a fortalecer interiormente a la persona para hacerla eficaz socialmente apoyándose en la consideración de ser humano como persona con potencialidades únicas.
      </p>
      <div className="flex-center-container">
        <Skill label="Singularidad" />
        <Skill label="Creatividad" />
        <Skill label="Autonomía" />
        <Skill label="Libertad" />
        <Skill label="Apertura" />
        <Skill label="Comunicación" />
      </div>
      <div className="to-center">
        <PrimaryButton text="Conoce más" to="/propuestaeducativa" icon />
      </div>
    </div>
  </div>
);

export default Model;