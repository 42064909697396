import React from 'react';
import { Link } from 'gatsby';

import valueIcon from '../../images/value.svg';
import newsIcon from '../../images/news.svg';

const weekDays = [
  "Domingo", 
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado"
]

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
]

const today = new Date();

const News = () => (
  <div className="news-section">
    <div className="wrapper">
      <div className="header">
        <h2>Bienvenido</h2>
        <span>
          {`${weekDays[today.getDay()]} ${today.getDate()} de ${months[today.getMonth()]} de ${today.getFullYear()}`}
        </span>
      </div>
      <div className="important">
        <h3>¡Importante!</h3>
        <p>
          Sabemos lo importante que es mantener la jornada de Sana Distancia en estos días cruciales por la salud de todos. Pero seguimos trabajando brindándote información o aclarando tus dudas en el correo <a href="mailto:contacto@ihb.edu.mx">contacto@ihb.edu.mx</a>.
        </p>
        <p>
          <b>Nuestro periodo de inscripciones al siguiente ciclo escolar está abierto.</b>
        </p>
      </div>
      <div className="box-container">
        <div className="value">
          <div className="title">
            <img src={valueIcon} alt=""/>
            <h3>Valor del Mes</h3>
          </div>
          <div className="body">
            <h1>Honestidad</h1>
            <p>
              La honestidad es una cualidad de los seres humanos que tiene una estrecha relación con los principios de verdad y justicia y con la integridad moral. Una persona honesta es aquella que procura siempre anteponer la verdad en sus pensamientos, expresiones y acciones.
            </p>
          </div>
        </div>

        <div className="news">
          <div className="title">
            <img src={newsIcon} alt=""/>
            <h3>Últimas Noticias</h3>
          </div>
          <div className="posts-container">
          <div className="post">
              <div className="title">Sanitización y Nebulización de Instalaciones</div>
              <p className="excerpt">
                Informamos a toda la comunidad escolar que el pasado 13 de marzo se sanitizaron y vaporizaron las instalaciones del colegio como medida preventiva en...
              </p>
              <Link to="/sanitizacion-y-nebulizacion-en-frio" className="read-more">Leer más</Link>
            </div>
            <div className="post">
              <div className="title">La importancia de la emoción para tener hijos resilientes</div>
              <p className="excerpt">
                Vivimos en una sociedad en la que continuamente proliferan mensajes sobre la necesidad e incluso obligación de ser feliz, como si de una máxima por la... 
              </p>
              <Link to="/la-importancia-de-la-emocion" className="read-more">Leer más</Link>
            </div>
            {/* <div className="post">
              <div className="title">7 Ejercicios para mejorar la atención en los niños</div>
              <p className="excerpt">
              ¿Cómo mejorar la atención en los niños? Te presentamos algunos ejercicios muy prácticos para que las distracciones de tu hijo ya no sean un problema sin solución.
              </p>
              <Link to="/" className="read-more">Leer más</Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default News; 