import React from 'react';
import { Slide } from 'react-slideshow-image';
import ModalVideo from 'react-modal-video'

import bg1 from '../images/gallery/img22.jpg';
import bg2 from '../images/gallery/img6.jpg';
import bg3 from '../images/gallery/img1.jpg';
import PrimaryButton from './atomic/PrimaryButton';

const slideImages = [
  bg3,
  bg2,
  bg1
];

const properties = {
  duration: 50000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  arrows: true,
  onChange: (oldIndex, newIndex) => {
    console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  }
}

class Slider extends React.Component {
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal () {
    this.setState({isOpen: true})
  }

  render() {
    return (
      <div className="slider-section">
        <div className="slider-container">
          <Slide {...properties}>
            <div className="each-slide">
              <div style={{'backgroundImage': `url(${slideImages[0]})`}}>
                <div className="wrapper">
                  <h1>Formación con Valores</h1>
                  <p>Comprometidos con una educación para la vida</p>
                  <div className="to-left">
                    <PrimaryButton to="/nosotros" text="Conoce más" icon />
                    <div className="secondary-button" onClick={this.openModal}>Ver Video</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="each-slide">
              <div style={{'backgroundImage': `url(${slideImages[1]})`}}>
                <div className="wrapper">
                  <h1>Formación con Valores</h1>
                  <p>Comprometidos con una educación para la vida</p>
                  <div className="to-left">
                    <PrimaryButton to="/nosotros" text="Conoce más" icon />
                    <div className="secondary-button" onClick={this.openModal}>Ver Video</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="each-slide">
              <div style={{'backgroundImage': `url(${slideImages[2]})`}}>
                <div className="wrapper">
                  <h1>Formación con Valores</h1>
                  <p>Comprometidos con una educación para la vida</p>
                  <div className="to-left">
                    <PrimaryButton to="/nosotros" text="Conoce más" icon />
                    <div className="secondary-button" onClick={this.openModal}>Ver Video</div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </div>
        <ModalVideo channel='vimeo' isOpen={this.state.isOpen} videoId='390666737' onClose={() => this.setState({isOpen: false})} />
      </div>
    )
  }
}

export default Slider;