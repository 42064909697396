import React from 'react';
import ReactPlayer from 'react-player';
import PrimaryButton from '../atomic/PrimaryButton';

import orangeCircle from '../../images/orange-circle.svg';

const About = () => (
  <div className="about-section">
    <div className="wrapper">
      <div className="image">
        <img src={orangeCircle} className="circle" alt=""/>
        <ReactPlayer width='100%' className="photo" url="https://vimeo.com/390666737" />
      </div>
      <div className="info">
        <p>
          Somos una Institución responsable con el desarrollo de las habilidades y conocimientos de nuestros niños, formándolos es un ambiente de armonía y adecuado el proceso de enseñanza – aprendizaje a las necesidades de cada uno de ellos, somos una comunidad pequeña porque buscamos hacer sentir tanto a los alumnos como a los padres de familia en un ambiente de confianza, donde sabemos quienes son cada uno de ellos. Trabajamos constantemente por mantener una comunicación con los Padres de Familia de los logros y dificultades de los pequeños, para trabajar de manera colegiada y alcancemos más rápido los objetivos.
        </p>
        <div className="to-right">
          <PrimaryButton text="Conoce más" to="/nosotros" icon />
        </div>
      </div>
    </div>
  </div>
);

export default About; 