import React from 'react';

import dots from '../images/orange-dots.svg';
import PrimaryButton from './atomic/PrimaryButton';

const JoinUs = () => (
  <div className="joinus-section wrapper">
    <img src={dots} className="dots" alt=""/>
    <h2>¡Únete a nuestra comunidad!</h2>
    <div className="to-center">
      <PrimaryButton text="Inscripciones" to="/inscripciones" icon />
    </div>
  </div>
);

export default JoinUs;